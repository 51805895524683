import { getRamenBentoAPIUrl } from 'lib/getRamenBentoAPIUrl';
import { stripLeadingSlash } from 'lib/urlUtils';

/**
 * Takes an object of format <code>{ '/api-endpoint': data, '/other-endpoint': moreData }</code> and
 * maps all endpoints (keys) of data to be prefixed with the environment-specific (client side,
 * server side) full URL of the Ramen Bento API URL. E.g. the above example becomes
 * <code>{ 'https://www.nbcnews.com/bentoapi/api-endpoint': data,
 * 'https://www.nbcnews.com/bentoapi/other-endpoint': moreData }</code> in production.
 * @param  {Object.<string, *>} data fallback data with endpoint keys which will be prefixed with
 * the respective full server or client side Ramen Bento API URL.
 * @return {Object.<string, *>} Fallback data with endpoints prefixed with the full Ramen Bento API
 * URL.
 */
export function mapBentoAPIEndpointsToSWRUrls(data) {
  const bentoAPIUrl = getRamenBentoAPIUrl();
  return Object.entries(data).reduce((accum, curr) => {
    const [endpoint, val] = curr;
    const strippedEndpoint = stripLeadingSlash(endpoint);

    // disabling no-param-reassign since the param we're reassigning to is the new object created
    // and passed into the `reduce` function.
    accum[`${bentoAPIUrl}/${strippedEndpoint}`] = val; // eslint-disable-line no-param-reassign
    return accum;
  }, {});
}
