// This file and logic was moved from a previous commit during A2A refactor.
// See Here: https://github.com/nbcnews/ramen/pull/2802/files#diff-29bda66271afa7cbaebe6ab6f53d16f4
import React from 'react';
import PropTypes from 'prop-types';
import BTE from 'lib/BTE';

import { stub as $t } from '@nbcnews/analytics-framework';

const trackingEvents = ['mbt_scrolled_percentage'];
$t('register', trackingEvents, { allowDuplicate: true });

export default class ScrollingAnalytics extends React.Component {
  static propTypes = {
    contentType: PropTypes.string.isRequired,
    contentUrl: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.trackScrollPercentage();
    BTE.on('scroll', this.trackScrollPercentage, 'throttle');
  }

  componentWillUnmount() {
    BTE.remove('scroll', this.trackScrollPercentage, 'throttle');
  }

  trackScrollPercentage = () => {
    /* eslint-disable camelcase */
    const { scrolled_25, scrolled_50, scrolled_75 } = this.state;
    const { top, bottom } = this.wrapper.getBoundingClientRect();

    // NO-OP if we've already emitted the events for this page
    // or if the user scrolled past the first article and hit refresh
    const percentage = (Math.abs(top) / (Math.abs(top) + bottom)) * 100;
    if (percentage > 100
      && scrolled_25
      && scrolled_50
      && scrolled_75) return;

    this.testAndEmitScrolledPercentage(25, percentage);
    this.testAndEmitScrolledPercentage(50, percentage);
    this.testAndEmitScrolledPercentage(75, percentage);
    /* eslint-disable camelcase */
  }

  testAndEmitScrolledPercentage(testedPercentage, currentPercentage) {
    const { contentType, contentUrl } = this.props;
    const scrolledStateKey = `scrolled_${testedPercentage}`;

    // eslint-disable-next-line react/destructuring-assignment
    if (this.state[scrolledStateKey] !== true && currentPercentage > testedPercentage) {
      $t('track', 'mbt_scrolled_percentage', {
        type: contentType,
        url: contentUrl,
        percent: testedPercentage,
      });

      this.setState({ [scrolledStateKey]: true });
    }
  }

  render() {
    const { children } = this.props;
    return (
      <div ref={(ref) => { this.wrapper = ref; }}>
        {children}
      </div>
    );
  }
}
