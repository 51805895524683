import getConfig from 'next/config';
import Link from 'http-link-header';
import { isTelemundoVertical } from 'lib/vertical';

export function setLinkHeaders(res, vertical) {
  const link = new Link(res.get('Link') || '');

  const {
    publicRuntimeConfig: {
      MPS_URL_NBCNEWS,
      MPS_URL_TELEMUNDO,
      IDENTITY_ENV,
    },
  } = getConfig();

  // Font files are sent over a CORS connection.
  link.set({
    uri: 'https://nodeassets.nbcnews.com',
    rel: 'preconnect',
    crossorigin: 'anonymous',
  });

  const preconnectOrigins = [
    'https://media-cldnry.s-nbcnews.com',
    'https://securepubads.g.doubleclick.net',
  ];

  if (vertical === 'today' || vertical === 'news') {
    const identitySdkHost = IDENTITY_ENV === 'production'
      ? 'https://id.nbcuni.com'
      : 'https://stage.id-envs.nbcuni.com';

    preconnectOrigins.push(identitySdkHost);
  }

  preconnectOrigins.forEach((preconnectOrigin) => link.set({
    rel: 'preconnect',
    uri: preconnectOrigin,
  }));

  const preloadScripts = [
    isTelemundoVertical(vertical) ? MPS_URL_TELEMUNDO : MPS_URL_NBCNEWS,
  ];

  preloadScripts.forEach((preloadScript) => link.set({
    uri: preloadScript,
    as: 'script',
    rel: 'preload',
  }));

  res.set('Link', link.toString());
}
