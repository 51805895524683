import scrollTo from 'lib/scrollToElement';
import Breakpoints from './Breakpoints';

const globalNavThreshold = 600;
const globalNavHeights = {
  news: 80,
  default: 50,
};
const localNavHeight = 80;

function removeAllHighlights() {
  const highlighted = document.querySelectorAll('.componentHighlight');
  Array.from(highlighted).forEach((el) => el.classList.remove('componentHighlight'));
  const curatorOverlays = document.querySelectorAll('span.curatorOverlay');
  Array.from(curatorOverlays).forEach((el) => el.remove());
}

function getHighlightsNoRelatedContent(highlightDiv) {
  const related = Array.from(
    highlightDiv.getElementsByClassName('related-content'),
  );
  if (!related.length) return [highlightDiv];
  const parent = related[0].parentElement;

  const highlightList = [];
  // Highlight the elements in the same level as the related content div
  highlightList.push(
    ...Array.from(parent.children).filter(
      (ch) => !ch.className.includes('related-content'),
    ),
  );
  // Highlight the elements outside of the div containing related content
  highlightList.push(
    ...Array.from(parent.parentElement.children).filter((ch) => ch !== parent),
  );

  return highlightList;
}

function getBstHighlightDiv(payload) {
  // Big Story Takeover related content doesn't render as related-content
  // Related content is rendered twice (desktop then mobile),
  //   so we need to highlight the proper div based on breakpoints
  if (payload.hasRelatedContent && payload.isCollapsed) {
    return document.querySelector(`[data-packageid='${payload.packageId}']`);
  }

  const divs = document.querySelectorAll(
    `[data-packageid='${payload.packageId}'] [data-contentid='${payload.contentId}']`,
  );
  if (divs.length > 1) return !Breakpoints.isSorM() ? divs[0] : divs[1];

  return divs[0];
}

function applyHighlight(highlightDiv) {
  highlightDiv.classList.add('componentHighlight');
  const curatorOverlay = document.createElement('span');
  curatorOverlay.classList.add('curatorOverlay');
  highlightDiv.append(curatorOverlay);
}

function removeHighlight(stopHighlightDiv) {
  stopHighlightDiv.classList.remove('componentHighlight');
  const overlay = stopHighlightDiv.querySelector('span.curatorOverlay');
  if (overlay) overlay.remove();
}

export function listenForCurationMessages(vertical, fetchDraft) {
  window.addEventListener('message', (event) => {
    const {
      data,
      data: { payload = {} },
    } = event;

    if (data === 'curationPing') {
      // Tell preview it supports postMessage reloading
      window.parent.postMessage('curationPing', '*');
    }

    if (data === 'refreshFront') {
      fetchDraft();
      window.parent.postMessage('refreshingFront', '*');
    }

    if (data.type === 'scrollTo') {
      const targetDiv = document.querySelector(
        `[data-packageid='${payload.packageId}'] [data-contentid='${payload.contentId}']`,
      ) || document.querySelector(`[data-packageid='${payload.packageId}']`);

      if (targetDiv) {
        let topHeight = targetDiv.getBoundingClientRect().top;
        topHeight += document.documentElement.scrollTop;

        // Global nav will disappear when this height is scrolled to
        const isPastThreshold = topHeight > globalNavThreshold;

        const globalNavHeight = globalNavHeights[vertical] || globalNavHeights.default;
        topHeight -= localNavHeight + (isPastThreshold ? 0 : globalNavHeight);

        scrollTo(topHeight - 5, 500, 'easeInOut', () => {
          scrollTo(topHeight, 0); // ensure final scroll is down
        });
      }
    }

    if (data.type === 'highlight') {
      // Remove all highlights
      removeAllHighlights();

      // Add new highlight
      let highlightDiv = document.querySelector(
        `[data-packageid='${payload.packageId}'] [data-contentid='${payload.contentId}']`,
      ) || document.querySelector(`[data-packageid='${payload.packageId}']`);

      const takeover = document.querySelector(
        `div[class~='big-story-takeover'][data-packageid='${payload.packageId}']`,
      );
      if (takeover && payload.contentId) { highlightDiv = getBstHighlightDiv(payload); }

      if (highlightDiv) {
        if (payload.hasRelatedContent && !payload.isCollapsed) {
          const highlightList = getHighlightsNoRelatedContent(highlightDiv);
          highlightList.forEach((div) => applyHighlight(div));
        } else {
          applyHighlight(highlightDiv);
        }
      }
    } else if (data.type === 'stopHighlight') {
      let stopHighlightDiv = document.querySelector(
        `[data-packageid='${payload.packageId}'] [data-contentid='${payload.contentId}']`,
      ) || document.querySelector(`[data-packageid='${payload.packageId}']`);

      const takeover = document.querySelector(
        `div[class~='big-story-takeover'][data-packageid='${payload.packageId}']`,
      );
      if (takeover && payload.contentId) { stopHighlightDiv = getBstHighlightDiv(payload); }

      if (stopHighlightDiv) {
        if (payload.hasRelatedContent && !payload.isCollapsed) {
          const highlightList = getHighlightsNoRelatedContent(stopHighlightDiv);
          highlightList.forEach((div) => removeHighlight(div));
        } else {
          removeHighlight(stopHighlightDiv);
        }
      } else {
        removeAllHighlights();
      }
    }
  });
}
