import React from 'react';
import getNextConfig from 'next/config';

import { RouteContext } from 'lib/ContextTypes';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { truncateString } from 'lib/stringUtils';

function CommerceAmazonContentInsights() {
  const {
    publicRuntimeConfig: {
      COMMERCE_AMAZON_CI_ENABLE: enabled,
    },
  } = getNextConfig();

  // Feature enabled
  if (!enabled) {
    return null;
  }

  const { domain, path } = React.useContext(RouteContext);

  let observer = null;
  let timeCurrent;
  let timeLastRun;
  let isRunning = false;

  const timeInterval = 3000;

  // Update tracking on matching urls
  const handler = () => {
    const { BI: { userInfo: { get: getUserInfo } = {} } = {} } = window;

    if (typeof getUserInfo === 'function') {
      const {
        cid: campaign = '',
        referrer: source = 'direct',
      } = getUserInfo();

      timeLastRun = Date.now();

      const params = {};
      const ascSource = truncateString(source, 64);
      let device = 'desktop';
      if (isMobileOnly) {
        device = 'mobile';
      } else if (isTablet) {
        device = 'tablet';
      }

      params.asc_campaign = truncateString(campaign, 64);
      params.asc_refurl = truncateString(`https://www.${domain}${path}`, 5120);

      Array.from(document.getElementsByTagName('a')).forEach((link) => {
        if (link.href) {
          try {
            const url = new URL(link.href);
            const regex = /.*amazon\./;
            if (url.hostname.match(regex)) {
              Object.entries(params).forEach(([key, value]) => {
                url.searchParams.set(key, value);
              });

              url.searchParams.set('asc_source', [ascSource, device].filter(Boolean).join('_'));

              // eslint-disable-next-line no-param-reassign
              link.href = url.href;
            }
          } catch (e) {
            // console.error(e);
          }
        }
      });
    }
  };

  const runner = () => {
    timeLastRun = Date.now();
    handler();
    isRunning = false;
  };

  React.useEffect(() => {
    observer = new MutationObserver(() => {
      timeCurrent = Date.now();
      if (timeCurrent - timeLastRun > timeInterval) {
        handler();
      } else if (!isRunning) {
        isRunning = true;
        setTimeout(runner, timeInterval);
      }
    });

    // Trigger on mount
    handler();
    // Watch for changes
    observer.observe(
      document.body,
      {
        childList: true,
        subtree: true,
        characterData: true,
      },
    );

    // Cleanup
    return () => {
      if (observer && typeof observer.disconnect === 'function') {
        observer.disconnect();
      }
    };
  }, []);

  return null;
}

CommerceAmazonContentInsights.propTypes = {};

export { CommerceAmazonContentInsights };
