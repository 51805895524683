import React from 'react';
import PropTypes from 'prop-types';

import { isTelemundoVertical } from 'lib/vertical';
import { initUniCheckout, uniCheckoutSrc } from 'lib/uniCheckout';

class UniversalCheckout extends React.Component {
  static propTypes = {
    vertical: PropTypes.string.isRequired,
  }

  componentDidMount() {
    const { vertical } = this.props;

    if (isTelemundoVertical(vertical)) {
      initUniCheckout();
    }
  }

  render() {
    const { vertical } = this.props;
    return isTelemundoVertical(vertical) ? <script src={uniCheckoutSrc} /> : null;
  }
}

export default UniversalCheckout;
