// Universal Checkout Scripts
const uniCheckoutScripts = {
  prod: 'https://commerce.nbcuni.com/public/widget/latest/bootstrap.js',
  stage: 'https://nonprod-commerce.nbcuni.com/uat/widget/latest/bootstrap.js',
};

// Environment Switch
export const uniCheckoutSrc = process.env.NODE_ENV === 'production'
  ? uniCheckoutScripts.prod
  : uniCheckoutScripts.stage;

// Init Universal Checkout
export function initUniCheckout() {
  try {
    window.uscWidget.init({
      theme: 'telemundo',
      lang: 'es-US',
      popoverRenewalTimeUnit: 'days',
      popoverRenewalTimeAmount: 60,
      networkBrand: 'nbcu-telemundo',
      externalLinks: {
        customerService: '/support/uc/#customer-service',
        returnsPolicy: '/support/uc/#shipping-returns-policy',
        faq: '/support/uc/#faqs',
        termsAndConditions: '/support/uc/#terms-of-service',
        // eslint-disable-next-line max-len
        privacyPolicy: 'https://www.nbcuniversal.com/privacy-policies/privacy-latinamerica?brandA=Telemundo_Networks&intake=Telemundo_Networks',
      },
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`Could not initialize Universal Checkout: ${err}`);
  }
}
