import React, { useEffect, useState } from 'react';
import Head from 'next/head';

const SCRIPT = 'https://widgets.northfork.se/dist/widgets.nbcu.bundle.js';
const CSS = 'https://widgets.northfork.se/dist/widgets.nbcu.bundle.css';

const NorthForkScript = () => {
  const [shouldRenderNorthforkScript, setShouldLoadNorthforkScript] = useState(false);
  useEffect(() => {
    setShouldLoadNorthforkScript(true);
  }, []);

  if (!shouldRenderNorthforkScript) {
    return null;
  }

  return (
    <Head>
      <link href={CSS} rel="stylesheet" />
      <script type="text/javascript" src={SCRIPT} id="nfw-v2" />
    </Head>
  );
};

export { NorthForkScript };
