const easings = {
  linear(t) {
    return t;
  },
  easeIn(t) {
    return t * t;
  },
  easeOut(t) {
    return t * (2 - t);
  },
  easeInOut(t) {
    return t < 0.5
      ? 2 * t * t
      : -1 + ((4 - (2 * t)) * t);
  },
};

export default function scrollTo(destination, duration = 200, easing = 'linear', callback) {
  const start = window.pageYOffset;
  const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight,
  );
  const windowHeight = window.innerHeight
    || document.documentElement.clientHeight
    || document.getElementsByTagName('body')[0].clientHeight;

  const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;

  // Ensure destination is a positive integer
  const normalizedDestinationOffset = Math.max(0, Math.round(destinationOffset));

  const destinationOffsetToScroll = Math.round(
    documentHeight - normalizedDestinationOffset < windowHeight
      ? documentHeight - windowHeight : normalizedDestinationOffset,
  );

  if ('requestAnimationFrame' in window === false) {
    window.scroll(0, destinationOffsetToScroll);
    if (callback) {
      callback();
    }
    return;
  }

  function scroll() {
    const now = 'now' in window.performance ? performance.now() : new Date().getTime();
    const time = Math.min(1, ((now - startTime) / duration));
    const timeFunction = easings[easing](time);
    const stepPosition = Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start);

    window.scroll(0, stepPosition);
    const currentScrollPosition = Math.ceil(window.pageYOffset);

    // There is a Chromium bug with calculation of page offset when browser zoom is applied
    // due to incorrect subpixel calculations:
    // https://bugs.chromium.org/p/chromium/issues/detail?id=440691
    if (currentScrollPosition === destinationOffsetToScroll
        || stepPosition === destinationOffsetToScroll) {
      if (callback) {
        callback();
      }
      return;
    }

    requestAnimationFrame(scroll);
  }

  scroll();
}
