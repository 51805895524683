import PropTypes from 'prop-types';

export const ScheduleItemPropType = PropTypes.shape({
  endDateTime: PropTypes.string.isRequired,
  startDateTime: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});

export const ScheduleDataPropType = PropTypes.shape({
  NEWS_NOW: PropTypes.shape({
    scheduleItems: PropTypes.arrayOf(ScheduleItemPropType),
  }),
  TODAY_ALL_DAY: PropTypes.shape({
    scheduleItems: PropTypes.arrayOf(ScheduleItemPropType),
  }),
});
